<template lang="pug">
div(class='grid-group')
  slot
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const GridGroup = defineComponent({
  props: {
    minWidth: { type: String, default: '300px' },
    gap: { type: String, default: '10px' },
  },
})
export default GridGroup
</script>
<style lang="stylus">
.grid-group
  --gap: v-bind(gap)
  --min-width: v-bind(minWidth)
  display: grid
  width: 100%
  grid-template-columns: repeat(auto-fill, minmax(var(--min-width), 1fr))
  grid-gap: 18px
</style>
